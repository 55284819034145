import * as buffer from "buffer";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { AnchorProvider } from "@project-serum/anchor";

window.Buffer = buffer.Buffer;

const network = 'https://burned-burned-cherry.solana-mainnet.quiknode.pro/de612326622c678d7264ad8543ae1b156eab7396/';
// const network = clusterApiUrl("devnet");
// const network = 'https://mainnet.helius-rpc.com/?api-key=0981068c-e48f-4119-b6b7-bbc7cc43d28a';
// const network = 'https://devnet.helius-rpc.com/?api-key=ea19dd82-e268-44f8-a909-87e015571a43';
// const network = 'http://localhost:8899';
const opts = {
  preflightCommitment: "processed",
};

export const getProvider = () => {
  const connection = new Connection(network, opts.preflightCommitment as any);
  const provider = new AnchorProvider(
    connection,
    window.solana,
    opts.preflightCommitment as any
  );
  return provider;
};
import React, { ReactElement, useState } from "react";
// import "./lever.scss";
import spinButtonPress from "../../img/spin-button-press.png";
import spinButton from "../../img/spin-button.png";

export const SpinButton = ({ onSpin }: any): ReactElement => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  return (
    <>
      <div className="utils">
        <img
          src={isMouseDown ? spinButtonPress : spinButton}
          alt="Spin"
          onMouseDown={() => setIsMouseDown(true)}
          onMouseUp={() => {
            setIsMouseDown(false);
            onSpin();
          }}
        ></img>
      </div>
    </>
  );
};
